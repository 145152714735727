.footer-contact-me__methods {
    display: flex;
    flex-direction: column;
    gap: .6rem;
    .footer-contact-me__methods__inner {
        display: flex;
        gap: 1rem;
        cursor: pointer;
        a {
            font-family: 'Poppins';
            color: var(--clr-secondary);
            font-weight: 400;
            transition: 125ms ease-in-out;
        }
        .footer-contact-me__methods__inner__icons {
            width: 25px;
            height: 25px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            transition: 125ms ease-in-out;
            filter : var(--filter-svg-secondary);
        }
        &:hover > .footer-contact-me__methods__inner__icons {
            filter : var(--filter-svg-primary);
        }
        &:hover > a {
            color: var(--clr-primary);
        }
        &:nth-child(1) .footer-contact-me__methods__inner__icons {
            background-image: url('./src/email.svg');
        }
        &:nth-child(2) .footer-contact-me__methods__inner__icons {
            background-image: url('./src/github.svg');
        }
        &:nth-child(3) .footer-contact-me__methods__inner__icons {
            background-image: url('./src/discord.svg');
        }
    }
}