@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    /* Colors */
    --clr-primary : #FD1F4A;
    --clr-primaryOffset : #ff4469;
    --filter-svg-primary: invert(37%) sepia(97%) saturate(5194%) hue-rotate(333deg) brightness(96%) contrast(108%);
    --clr-border-primary: #FD1F4A;
    --clr-secondary : #FBBD0D;
    --clr-secondaryOffset : #facc4c;
    --filter-svg-secondary: invert(67%) sepia(75%) saturate(933%) hue-rotate(351deg) brightness(108%) contrast(102%);
    --clr-border-secondary: #FBBD0D;
    --clr-text: #FAF5E6;
    --clr-textOffset: #d7d7d7;
    --clr-background : #000c14;
    --clr-backgroundOffset : #001625;
   /* Font Family */
   --font-primary: 'Poppins';
   --font-secondary: "'Times New Roman', Times, serif";
}
*, *:before, *:after {
    box-sizing: border-box;
    position: relative;
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}
html, body {
    // scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
body {
    transition: color .15s,background-color .15s;
    background-color: var(--clr-background);
}
a {
    cursor: pointer;
}
img {
    pointer-events: none;
}