.status-box {
    width: 90%;
    max-width: 850px;
    margin: auto;
    transition: 300ms ease-in-out;
    color: var(--clr-text);
    font-family: 'Poppins';
    display: flex;
    flex-direction: column;
    .status-box-header {
        h3 {
            font-weight: 300;
        }
        p {
            font-weight: 300;
        }
        padding: 1rem 1rem 0 1rem;
        display: flex;
        justify-content: space-between;
        .status-box-header-icons {
            display: flex;
            gap: 1rem;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.25rem;
                .noissuesico,
                .incidentico,
                .outageico {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 3px solid;
                }
                .noissuesico {
                    border-color: rgb(0, 222, 81);
                }
                .incidentico {
                    border-color: rgb(255, 174, 0);
                }
                .outageico {
                    border-color: rgb(255, 34, 0);
                }
            }
        }
    }
    .status-box-body {
        h3 {
            font-weight: 300;
        }
        p {
            font-weight: 300;
        }
        padding: 0 1rem 1rem 1rem;
        div {
            padding: 0.5rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
                font-size: 0.9rem;
            }
        }
        div:nth-child(1) {
            .statusonline {
                color: rgb(0, 222, 81);
                border: 1px solid rgb(0, 222, 81);
                padding: 0.2rem 0.4rem;
                border-radius: 15px;
                animation: statuspulseonline 2s infinite;
            }
            @keyframes statuspulseonline {
                0% {
                    text-shadow: 0 0 20px;
                    box-shadow: 0 0 10px 5px rgba(0, 222, 81, 0.091);
                }
                50% {
                    text-shadow: 0 0 10px;
                    box-shadow: 0 0 10px 10px rgba(0, 222, 81, 0.091);
                }
                100% {
                    text-shadow: 0 0 20px;
                    box-shadow: 0 0 10px 5px rgba(0, 222, 81, 0.091);
                }
            }
            .statusoffline {
                color: rgb(255, 34, 0);
                border: 1px solid rgb(255, 34, 0);
                padding: 0.2rem 0.4rem;
                border-radius: 15px;
                animation: statuspulseoffline 2s infinite;
            }
            @keyframes statuspulseoffline {
                0% {
                    text-shadow: 0 0 20px;
                    box-shadow: 0 0 10px 5px rgba(222, 26, 0, 0.091);
                }
                50% {
                    text-shadow: 0 0 10px;
                    box-shadow: 0 0 10px 10px rgba(222, 26, 0, 0.091);
                }
                100% {
                    text-shadow: 0 0 20px;
                    box-shadow: 0 0 10px 5px rgba(222, 26, 0, 0.091);
                }
            }
        }
        div:nth-child(2) {
            .pinglow {
                color: rgb(0, 222, 81);
            }
            .pingmid {
                color: rgb(255, 174, 0);
            }
            .pinghigh {
                color: rgb(255, 34, 0);
            }
        }
        .server-customers {
            div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 0.75rem;
                p {
                    font-size: 2rem;
                }
            }
        }
        .server-usage {
            display: block;
            .server-usage-item {
                h3 {
                    min-width: 30%;
                }
                display: flex;
                justify-content: space-between;
                .server-usage-pb-container {
                    background-color: var(--clr-backgroundOffset);
                    width: 100%;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                    p {
                        padding-right: 1.5px;
                    }
                    .server-usage-pb {
                        height: 20px;
                        border-radius: 5px;
                    }
                    .server-usage-pblow {
                        background-color: rgb(0, 222, 81);
                    }
                    .server-usage-pbmid {
                        background-color: rgb(255, 174, 0);
                    }
                    .server-usage-pbhigh {
                        background-color: rgb(255, 34, 0);
                    }
                }
            }
        }
    }
}
@media (max-width:700px) {
    .status-box {
        width: 97.5%;
        h3 {
            font-size: 1rem;
        }
        p {
            font-size: 0.8rem !important;
        }
        .status-box-header {
            padding: 0.5rem;
            .status-box-header-icons {
                display: flex;
                gap: 0.35rem;
                div {
                    gap: 0.15rem;
                    .noissuesico,
                    .incidentico,
                    .outageico {
                        border-width: 2px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
        .status-box-body {
            padding: 0.5rem;
            div {
                padding: 0.5rem 0;
            }
            .server-customers {
                div {
                    gap: 0.5rem;
                    p {
                        font-size: 1.5rem !important;
                    }
                }
            }
        }
    }
}
@media (max-width:500px) {
    .status-box {
        .status-box-header {
            .status-box-header-icons {
                display: flex;
                gap: 0.35rem;
                div {
                    p {
                        display: none;
                    }
                }
            }
        }
        .status-box-body {
            .server-usage-item {
                h3 {
                    min-width: 40% !important;
                }
            }
        }
    }
}
@media (max-width:500px) {
    .status-box {
        .status-box-body {
            .server-usage-item {
                h3 {
                    min-width: 45% !important;
                }
            }
        }
    }
}