.page-content {
    max-width: 850px;
    margin: 2rem auto;
    padding: 0 1rem;
}
.dt {
    font-family: 'Poppins';
    font-weight: 200;
    color: var(--clr-primary);
    line-height: 1.6;
    margin: 1rem 0;
}
.dh {
    font-family: 'Poppins';
    font-weight: 500;
    color: var(--clr-secondary);
    line-height: 1.6;
    margin: 1rem 0 0.1rem 0;
}
.dp {
    font-family: 'Poppins';
    font-weight: 300;
    color: var(--clr-text);
    line-height: 1.6;
    margin: 0.3rem 0 0.1rem 0;
}
pre {
    background-color: var(--clr-backgroundOffset);
    color: var(--clr-textOffset);
    max-width: 100%;
    overflow-x: auto;
    line-height: 1.6;
    padding: 1rem;
    margin: 0.3rem 0 0.1rem 0;
    border-radius: 0.5rem;
}
.hr {
    border: 1px solid var(--clr-backgroundOffset);
    opacity: 70%;
    margin: 1rem 0;
}
code {
    background-color: var(--clr-background);
    padding: 2.5px;
    border-radius: 5px;
    font-size: 0.9rem;
}