.home-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 5rem 0;
    .home-header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5rem;
        .home-header-content__left {
            h1 {
                color: var(--clr-secondary);
                font-family: 'Poppins';
                font-size: 4rem;
            }
            p {
                max-width: 300px;
                color: var(--clr-text);
                font-family: 'Poppins';
                font-size: 0.9rem;
                opacity: 0.75;
            }
            .Phoenix {
              background: linear-gradient(to right, rgb(249, 195, 0) 10%, rgb(250, 2, 52) 30%, rgb(249, 195, 0) 90%);
              background-size: 600% auto;
              color: #ffffff;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              animation: shine 10s linear infinite;
              @keyframes shine {
                to {
                  background-position: 600% center;
                }
              }
            }
            .invite-btn {
              margin-top: 1rem;
              padding: 2px 2px;
              width: 170px;
              height: 40px;
              border-radius: 12px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              cursor: pointer;
              transition: transform .3s ease;
              font-family: 'Poppins';
              font-weight: 500;
              letter-spacing: 0.1rem;
              &:hover {
                transform: scale(1.05);
              }
              &:active {
                transform: scale(0.95);
              }
            }
            .invite-btn::before{
              content: '';
              position: absolute;
              width: 110%;
              height: 600%;
              background: conic-gradient(
                  var(--clr-primary),
                  var(--clr-secondary),
                  var(--clr-primary),
                );;
              animation: spin 1.5s infinite linear;
            }
            .invite-btn::after{
              border-radius: 10px;
              content: 'INVITE TO DISCORD';
              position: absolute;
              background-color: var(--clr-background);
              width: 97%;
              height: 87%;
              border-radius: 10px;
              font-size: 0.8rem;
              color: #fff;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            // .invite-btn:hover::before{
            //   animation: spin 1.5s infinite linear;
            // }
            @keyframes spin {
              to{
                transform: rotate(360deg);
              }
            }
        }
        .home-header-content__right {
            img {
                filter: var(--filter-svg-primary);
                max-width: 300px;
            }
        }
    }
    .home-footer {
        max-width: 680px;
        .header-service {
          background: linear-gradient(to right, rgb(249, 195, 0) 0%, rgb(250, 2, 52) 100%);
          background-size: 100% auto;
          color: #ffffff;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
      }
    }
}
.home-introduction {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 5rem;
    .home-introduction__content {
        display: flex;
        justify-content: center;
        margin: auto;
        gap: 4rem;
        flex-direction: column;
        .card {
          &:nth-child(odd) {
              transform: translateX(-100px);
              border-right: 2px solid var(--clr-secondary);
              border-bottom: 2px solid var(--clr-secondary);
          }
          &:nth-child(even) {
              transform: translateX(100px);
              border-left: 2px solid var(--clr-primary);
              border-bottom: 2px solid var(--clr-primary);
          }
          max-width: 500px;
          background-color: var(--clr-backgroundOffset);
          padding: 1.5rem;
          border-radius: 30px;
          .card-header {
              h3 {
                  margin: 0;
              }
          }
        }
      &::before {
        content: '';
        width: 2px;
        height: 0%;
        background: linear-gradient(to bottom, var(--clr-primary) 25%, var(--clr-secondary) 100%);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: heightintroline 1s ease-in-out forwards;
        animation-delay: 5s;
      }
      @keyframes heightintroline {
        to {
          height: 95%;
        }
      }
    }
}
// text-focus-in
.text-focus-in{-webkit-animation:1s cubic-bezier(.55,.085,.68,.53) both text-focus-in;animation:1s cubic-bezier(.55,.085,.68,.53) both text-focus-in}@-webkit-keyframes text-focus-in{0%{-webkit-filter:blur(12px);filter:blur(12px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}@keyframes text-focus-in{0%{-webkit-filter:blur(12px);filter:blur(12px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}

// roll-in-blurred-right
.roll-in-blurred-right{-webkit-animation:2.5s cubic-bezier(.23,1,.32,1) both roll-in-blurred-right;animation:2.5s cubic-bezier(.23,1,.32,1) both roll-in-blurred-right}@-webkit-keyframes roll-in-blurred-right{0%{-webkit-transform:translateX(1000px) rotate(720deg);transform:translateX(1000px) rotate(720deg);-webkit-filter:blur(50px) var(--filter-svg-primary);filter:blur(50px) var(--filter-svg-primary);opacity:0}100%{-webkit-transform:translateX(0) rotate(0);transform:translateX(0) rotate(0);-webkit-filter:blur(0) var(--filter-svg-primary);filter:blur(0) var(--filter-svg-primary);opacity:1}}@keyframes roll-in-blurred-right{0%{-webkit-transform:translateX(1000px) rotate(720deg);transform:translateX(1000px) rotate(720deg);-webkit-filter:blur(50px) var(--filter-svg-primary);filter:blur(50px) var(--filter-svg-primary);opacity:0}100%{-webkit-transform:translateX(0) rotate(0);transform:translateX(0) rotate(0);-webkit-filter:blur(0) var(--filter-svg-primary);filter:blur(0) var(--filter-svg-primary);opacity:1}}@keyframes move{100%{transform:translate3d(0,0,1px) rotate(360deg)}}

// 
.roll-in-blurred-left{-webkit-animation:roll-in-blurred-left .65s cubic-bezier(.23,1.000,.32,1.000) both;animation:roll-in-blurred-left .65s cubic-bezier(.23,1.000,.32,1.000) both}
@-webkit-keyframes roll-in-blurred-left{0%{-webkit-transform:translateX(-1000px) rotate(-720deg);transform:translateX(-1000px) rotate(-720deg);-webkit-filter:blur(50px);filter:blur(50px);opacity:0}100%{-webkit-transform:translateX(0) rotate(0deg);transform:translateX(0) rotate(0deg);-webkit-filter:blur(0);filter:blur(0);opacity:1}}@keyframes roll-in-blurred-left{0%{-webkit-transform:translateX(-1000px) rotate(-720deg);transform:translateX(-1000px) rotate(-720deg);-webkit-filter:blur(50px);filter:blur(50px);opacity:0}100%{-webkit-transform:translateX(0) rotate(0deg);transform:translateX(0) rotate(0deg);-webkit-filter:blur(0);filter:blur(0);opacity:1}}

// 
.tracking-in-expand{-webkit-animation:tracking-in-expand .7s cubic-bezier(.55,.085,.68,.53) both;animation:tracking-in-expand .7s cubic-bezier(.55,.085,.68,.53) both}
@-webkit-keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}@keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}

// 
@keyframes pulse-jump {
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.pulse-jump{-webkit-animation:3s ease-in-out both pulse-jump infinite;animation:3s ease-in-out both pulse-jump infinite}

// 
.slide-in-left{-webkit-animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both}
@-webkit-keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(-100px);transform:translateX(-100px);opacity:1}}@keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(-100px);transform:translateX(-100px);opacity:1}}

// 
.slide-in-right{-webkit-animation:slide-in-right .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-right .5s cubic-bezier(.25,.46,.45,.94) both}
@-webkit-keyframes slide-in-right{0%{-webkit-transform:translateX(1000px);transform:translateX(1000px);opacity:0}100%{-webkit-transform:translateX(100px);transform:translateX(100px);opacity:1}}@keyframes slide-in-right{0%{-webkit-transform:translateX(1000px);transform:translateX(1000px);opacity:0}100%{-webkit-transform:translateX(100px);transform:translateX(100px);opacity:1}}

@media (max-width:750px) {
  .home-header {
    margin: 1rem 0 2rem 0;
    .home-header-content {
        flex-direction: column-reverse;
        gap: 0rem;
        .home-header-content__left {
          padding: 0 1rem;
            h1 {
                font-size: 4rem;
            }
            p {
                max-width: 370px;
                width: auto;
                font-size: 0.9rem;
            }
        }
        .home-header-content__right {
            img {
                display: none;
            }
        }
    }
    .home-footer {
        .header-service {
          font-size: 1.5rem;
          max-width: 400px;
          padding: 0rem 1rem;
          width: auto;
      }
    }
  }
  .home-introduction {
    gap: 1rem;
    .home-introduction__content {
        padding: 0 1rem;
        .card {
          &:nth-child(odd) {
              transform: translateX(0px);
          }
          &:nth-child(even) {
              transform: translateX(0px);
          }
          max-width: 500px;
          width: auto;
          background-color: var(--clr-backgroundOffset);
          padding: 1.5rem;
          border-radius: 30px;
        }
    }
    .home-introduction__footer {
      font-size: 0.9rem;
    }
  }
  .slide-in-left{-webkit-animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-left .5s cubic-bezier(.25,.46,.45,.94) both}
  @-webkit-keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0px);transform:translateX(0px);opacity:1}}@keyframes slide-in-left{0%{-webkit-transform:translateX(-1000px);transform:translateX(-1000px);opacity:0}100%{-webkit-transform:translateX(0px);transform:translateX(0px);opacity:1}}
  .slide-in-right{-webkit-animation:slide-in-right .5s cubic-bezier(.25,.46,.45,.94) both;animation:slide-in-right .5s cubic-bezier(.25,.46,.45,.94) both}
  @-webkit-keyframes slide-in-right{0%{-webkit-transform:translateX(1000px);transform:translateX(1000px);opacity:0}100%{-webkit-transform:translateX(0px);transform:translateX(0px);opacity:1}}@keyframes slide-in-right{0%{-webkit-transform:translateX(1000px);transform:translateX(1000px);opacity:0}100%{-webkit-transform:translateX(0px);transform:translateX(0px);opacity:1}}
}