.footer-container {
    width: 100%;
    background-color: var(--clr-backgroundOffset);
    margin-top: 1rem;
    .footer-inner {
        display: flex;
        justify-content: center;
        padding: 3rem;
        width: 100%;
        gap: 2rem;
        .footer-phoenix {
            max-width: 250px;
            min-width: 250px;
            display: flex;
            margin-top: -12.5px;
            flex-direction: column;
            .footer-phoenix__header {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                img {
                    max-height: 40px;
                    filter : var(--filter-svg-primary);
                }
                h1 {
                    font-family: 'Poppins';
                    color: var(--clr-primary);
                    font-size: 2.5rem;
                }
            }
            p {
                color: var(--clr-text);
                font-family: 'Poppins';
                font-size: 0.9rem;
                opacity: 0.5;
            }
        }
        .footer-links {
            display: flex;
            flex-direction: column;
            gap: .6rem;
            max-width: 250px;
            min-width: 250px;
            h3 {
                color: var(--clr-primary);
                font-family: 'Poppins';
                font-weight: 500;
            }
            .footer-links__link {
                display: flex;
                align-items: center;
                gap: 1rem;
                p {
                    font-family: 'Poppins';
                    color: var(--clr-secondary);
                    font-weight: 400;
                    transition: 125ms ease-in-out;
                }
                .footer-links__link__icon {
                    width: 25px;
                    height: 25px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    transition: 125ms ease-in-out;
                    filter : var(--filter-svg-secondary);
                }
                &:hover > p {
                    color: var(--clr-primary);
                }
                &:hover > .footer-links__link__icon {
                    filter : var(--filter-svg-primary);
                }
                &:nth-child(2) .footer-links__link__icon {
                    background-image: url('./src/privacypolicy.svg');
                }
                &:nth-child(3) .footer-links__link__icon {
                    background-image: url('./src/termsofservice.svg');
                }
                &:nth-child(4) .footer-links__link__icon {
                    background-image: url('./src/commands.svg');
                }
                &:nth-child(5) .footer-links__link__icon {
                    background-image: url('./src/updatenotes.svg');
                }
            }
        }
        .footer-contact-me {
            display: flex;
            flex-direction: column;
            max-width: 250px;
            min-width: 250px;
            gap: .6rem;
            h3 {
                color: var(--clr-primary);
                font-family: 'Poppins';
                font-weight: 500;
            }
            .footer-contact-me__methods {
                display: flex;
                flex-direction: column;
                gap: .6rem;
                .footer-contact-me__methods__inner {
                    display: flex;
                    gap: 1rem;
                    cursor: pointer;
                    a {
                        font-family: 'Poppins';
                        color: var(--clr-secondary);
                        font-weight: 400;
                        transition: 125ms ease-in-out;
                    }
                    .footer-contact-me__methods__inner__icons {
                        width: 25px;
                        height: 25px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: 125ms ease-in-out;
                        filter : var(--filter-svg-secondary);
                    }
                    &:hover > .footer-contact-me__methods__inner__icons {
                        filter : var(--filter-svg-primary);
                    }
                    &:hover > a {
                        color: var(--clr-primary);
                    }
                    &:nth-child(1) .footer-contact-me__methods__inner__icons {
                        background-image: url('./src/email.svg');
                    }
                    &:nth-child(2) .footer-contact-me__methods__inner__icons {
                        background-image: url('./src/github.svg');
                    }
                    &:nth-child(3) .footer-contact-me__methods__inner__icons {
                        background-image: url('./src/discord.svg');
                    }
                }
            }
        }
        .footer-donate {
            display: flex;
            flex-direction: column;
            max-width: 335px;
            min-width: 250px;
            .footer-donate__card {
                padding: 1.25rem;
                border-radius: 20px;
                background-color: var(--clr-secondary);
                h3 {
                    color: var(--clr-background);
                    font-family: 'Poppins';
                    font-weight: 600;
                    .footer-donate__card__icon {
                        background-image: url('./src/heart.svg');
                        width: 25px;
                        height: 25px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: 125ms ease-in-out;
                        filter : var(--filter-svg-secondary);
                    }
                }
                p {
                    color: var(--clr-background);
                    font-family: 'Poppins';
                    font-size: 0.9rem;
                    opacity: 0.9;
                }
                h4 {
                    color: var(--clr-primary);
                    font-family: 'Poppins';
                    font-weight: 500;
                }
            }
        }
    }
}
@media (max-width:1280px) {
    .footer-container {
        margin-top: 10rem;
    }
    .footer-links,
    .footer-contact-me {
        margin-top: 5rem !important;
        min-width: unset !important;
        max-width: unset !important;
    }
    .footer-phoenix {
        margin-top: +72.5px !important;
    }
    .footer-donate {
        position: absolute;
        top: -5rem;
        max-width: 500px !important;
    }
}
@media (max-width:794px) {
    .footer-container {
        .footer-inner {
            flex-wrap: wrap;
            padding: 3rem;
            gap: 2rem;
            .footer-contact-me {
                margin-top: 0rem !important;
            }
        }
    }
}
@media (max-width:552px) {
    .footer-container {
        .footer-inner {
            .footer-donate {
                padding: 1rem;
            }
            .footer-links {
                margin-top: 0rem !important;
                margin-right: 2.25rem;
            }
        }
    }
}
@media (max-width:472px) {
    .footer-container {
        .footer-inner {
            .footer-donate {
                .footer-donate__card {
                    margin-top: -2.5rem;
                }
            }
        }
    }
}
@media (max-width:429px) {
    .footer-container {
        .footer-inner {
            .footer-donate {
                padding: 1rem;
                .footer-donate__card {
                    margin-top: -3.5rem;
                }
            }
        }
    }
}