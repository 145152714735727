.navigation-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .navigation-inner {
        padding: 3rem 2rem;
        gap: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        min-width: 100%;
        .navigation-container__left {
            display: flex;
            align-items: center;
            gap: 4rem;
            font-size: 1.1rem;
            .navigation-container__left__logo {
                display: flex;
                align-items: center;
                img {
                    max-height: 40px;
                    filter : var(--filter-svg-primary);
                }
                h1 {
                    font-family: 'Poppins';
                    color: var(--clr-primary);
                    font-size: 2.5rem;
                }
            }
            .navigation-container__left__links {
                display: flex;
                gap: 2rem;
                font-size: 1.1rem;
                p {
                    font-family: 'Poppins';
                    color: var(--clr-secondary);
                    font-weight: 400;
                    transition: 125ms ease-in-out;
                }
                .navigation-container__left_links__link {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    .navigation-container__left_links__link__icon {
                        width: 25px;
                        height: 25px;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        transition: 125ms ease-in-out;
                        filter: var(--filter-svg-secondary);
                    }
                    &:hover > p {
                        color: var(--clr-primary);
                    }
                    &:hover > .navigation-container__left_links__link__icon {
                        filter: var(--filter-svg-primary);
                    }
                    &:nth-child(1) .navigation-container__left_links__link__icon {
                        background-image: url('./src/home.svg');
                    }
                    &:nth-child(2) .navigation-container__left_links__link__icon {
                        background-image: url('./src/docs.svg');
                    }
                    &:nth-child(3) .navigation-container__left_links__link__icon {
                        background-image: url('./src/status.svg');
                    }
                    &:nth-child(4) .navigation-container__left_links__link__icon {
                        background-image: url('./src/support.svg');
                    }
                }
            }
        }
        .navigation-container__right {
            .navigation-container__right__items {
                display: flex;
                align-items: center;
                p {
                    background-color: var(--clr-primary);
                    color: var(--clr-secondary);
                    font-family: 'Poppins';
                    font-weight: 400;
                    transition: 125ms ease-in-out;
                    border: 2px solid var(--clr-primary);
                    padding: 5px 7.5px;
                    border-radius: 10px;
                    &:hover {
                        color: var(--clr-secondary);
                        border-color: var(--clr-secondary);
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
.navigation-container #slidenavmenu {
    display: none;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: height .4s cubic-bezier(.68, .16, .32, .89);
    height: 0;
    background-color: var(--clr-backgroundOffset);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    gap: 0.3rem;
    .slide-nav-item {
        .slide-nav-item__link {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            p {
                font-family: 'Poppins';
                color: var(--clr-secondary);
                font-weight: 400;
                transition: 125ms ease-in-out;
                font-size: 1.1rem;
            }
            .slide-nav-item__link__icon {
                width: 25px;
                height: 25px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                transition: 125ms ease-in-out;
                filter: var(--filter-svg-secondary);
            }
            &:hover > .slide-nav-item__link__icon {
                filter: var(--filter-svg-primary);
            }
            &:hover > p {
                color: var(--clr-primary);
            }
        }
        &:nth-child(1) .slide-nav-item__link__icon {
            background-image: url('./src/home.svg');
        }
        &:nth-child(2) .slide-nav-item__link__icon {
            background-image: url('./src/docs.svg');
        }
        &:nth-child(3) .slide-nav-item__link__icon {
            background-image: url('./src/status.svg');
        }
        &:nth-child(4) .slide-nav-item__link__icon {
            background-image: url('./src/support.svg');
        }
    } 
    .slide-nav-item__invdc {
        display: none;
        p {
            background-color: var(--clr-primary);
            color: var(--clr-secondary);
            font-family: 'Poppins';
            font-weight: 400;
            transition: 125ms ease-in-out;
            border: 2px solid var(--clr-primary);
            padding: 5px 7.5px;
            border-radius: 10px;
            &:hover {
                color: var(--clr-secondary);
                border-color: var(--clr-secondary);
                background-color: transparent;
            }
        }
    }
}
.navigation-container #slidenavmenu li {
    font-family: 'Poppins';
    padding: 0.3rem;
}
.navigation-container #slidenavmenu a {
    color: var(--clr-secondary);
}
.navigation-container #slidenavmenu strong {
    font-family: 'Poppins';
    color: var(--clr-secondary);
    font-size: 1.1rem;
}
.slidenavtogglebars-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigation-container #slidenavtoggle {
    display: none;
    position: relative;
    width: 40px;
    height: 40px;
    opacity: 0;
    cursor: pointer;
}
.navigation-container .slidenavtogglebars-container {
    cursor: pointer;
    top: -6.5px;
    left: -6.5px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 3.325rem;
    height: 3.325rem;
    border-radius: 50%;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}
.navigation-container #slidenavtoggle:hover~.slidenavtogglebars-container {
    filter: brightness(.90);
}
.navigation-container .slidenavtogglebars span {
    border-radius: 1rem;
    display: block;
    width: 1.5rem;
    height: 3px;
    background-color: var(--clr-secondary);
    z-index: 10;
    transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.navigation-container .slidenavtogglebars span:nth-child(1) {
    margin-bottom: 5px;
}
.navigation-container .slidenavtogglebars span:nth-child(2) {
    width: 1.1rem;
    margin-left: 3px;
}
.navigation-container .slidenavtogglebars span:nth-child(3) {
    margin-top: 5px;
}
.navigation-container #slidenavmenu strong {
    margin-right: 1rem;
}
.navigation-container #slidenavtoggle[type="checkbox"]:checked~.slidenavtogglebars-container span:nth-child(2) {
    display: none;
}
.navigation-container #slidenavtoggle[type="checkbox"]:checked~.slidenavtogglebars-container span:nth-child(1) {
    transform: rotate(-405deg) translateY(9px);
    top: -2px;
    right: 6px;
}
.navigation-container #slidenavtoggle[type="checkbox"]:checked~.slidenavtogglebars-container span:nth-child(3) {
    transform: rotate(405deg) translateY(-3px);
    top: -2px;
    right: 2px;
}
@media (max-width:999px) {
    .navigation-container {
        .navigation-inner {
            .navigation-container__left {
                gap: 2rem;
                .navigation-container__left__logo {
                    h1 {
                        display: none;
                    }
                }
            }
        }
    }
}
@media (max-width:850px) {
    .navigation-container {
        .navigation-inner {
            .navigation-container__left {
                .navigation-container__left__links {
                    display: none;
                }
                .navigation-container__left__logo {
                    h1 {
                        display: block;
                        font-size: 2rem;
                    }
                }
            }
            .navigation-container__right {
                .navigation-container__right__items {
                    gap: 1rem;
                }
            }
        }
    }
    .navigation-container #slidenavmenu {
        display: flex !important;
    }
    .navigation-container #slidenavtoggle {
        display: block !important;
    }
    .navigation-container .slidenavtogglebars-container {
        display: flex !important;
    }
}
@media (max-width:500px) {
    .navigation-container {
        .navigation-inner {
            .navigation-container__right {
                .navigation-container__right__items a {
                    display: none;
                }
            }
        }
    }
    .navigation-container #slidenavmenu {
        .slide-nav-item__invdc {
            display: block;
        }
    }
}
@media (max-width:350px) {
    .navigation-container {
        .navigation-inner {
            .navigation-container__left {
                .navigation-container__left__logo {
                    h1 {
                        display: none;
                    }
                }
            }
        }
    }
}
// .active::after {
//   /* styles for active link */
// //   background: var(--clr-primary);
// //   content: '';
// //   display: block;
// //   width: 100%;
// //   height: 4px;
// //   transition: 300ms cubic-bezier(1, 0, 0, 1);
//     color: red;
// }